<template>
    <details class="test-details test-details--fixed" :open="HasEnabledValues">
        <summary>
            debug
        </summary>

        <div
            v-for="(_, field, i) in debugInfoBooleans"
            :key="i" class="display-flex align-items-center text-size-s space-mt-s"
        >
            <NewSwitch
                v-model="debugInfoBooleans[field].val"
                class="space-mr-s"
                size="xs"
            />
            {{ field }}
        </div>
    </details>

    <NewContainer
        v-if="debugInfoBooleans.breakpoints.val"
        :test-border="debugInfoBooleans.containerBorder.val"
    >
        <details
            class="test-details"
            :open="debugInfoBooleans.breakpoints.opened"
            @click.prevent="debugInfoBooleans.breakpoints.opened = !debugInfoBooleans.breakpoints.opened"
        >
            <summary>
                Breakpoints debug
            </summary>

            <div class="space-mt-s">
                <p>breakpointEnum: {{ breakpointEnum }}</p>
                <p>current breakpoints rules: {{ breakpointsRules }}</p>
                <p>window width: {{ props.windowWidth }}</p>

                <h5>OPENED RULES:</h5>
                <p>{{ MAX_BREAKPOINT_OBJ.OPENED }}</p>

                <h5>CLOSED RULES:</h5>
                <p>{{ MAX_BREAKPOINT_OBJ.CLOSED }}</p>
            </div>
        </details>
    </NewContainer>
</template>

<script setup lang="ts">
import { NewSwitch } from 'vue-components2'
import { computed, inject } from 'vue'

import type { useBreakpoint } from 'platform-module'
import type { BreakpointRanges } from '@/static'

import { MAX_BREAKPOINT_OBJ, MaxBreakpointStates } from '@/static'
import { useDebugInfoStore } from '@/stores'

const props = defineProps({
    isAsideOpened: {
        type: Boolean,
        default: false
    },
    windowWidth: {
        type: Number,
        default: 0
    }
})

type BreakpointComposable = ReturnType<typeof useBreakpoint>

const { breakpointEnum } = inject<BreakpointComposable>('breakpointComposable')

const { debugInfoBooleans, HasEnabledValues } = useDebugInfoStore()

const breakpointsRules = computed<BreakpointRanges>(() => {
    const state = props.isAsideOpened ? MaxBreakpointStates.OPENED : MaxBreakpointStates.CLOSED
    return MAX_BREAKPOINT_OBJ[state][breakpointEnum.value as keyof typeof MAX_BREAKPOINT_OBJ[typeof state]]
})
</script>

<style scoped lang="scss">
// debug info styles
// TODO: remove later
.test-details {
    padding: 10px;
    border: 1px solid var(--border-primary);
    border-radius: var(--border-radius-m);
    background: #fff;
    margin-bottom: var(--space-l);

    summary {
        cursor: pointer;
    }

    &--fixed {
        z-index: 99999;
        position: fixed;
        bottom: 140px;
        right: 10px;
        margin: 0;
    }
}
</style>
